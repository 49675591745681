<template>
  <div>
    <BarChart :style="'height:' + canvasHeight" v-bind="doughnutChartProps" />
  </div>
</template>

<script>
import { useDoughnutChart, BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { ref, computed, defineComponent } from "@vue/composition-api";
import DateTimeService from '@/services/DateTimeService'

//const qs = require('qs');

Chart.register(...registerables);
export default defineComponent({
  name: "Chart",
  components: {
    BarChart
  },
  props: {
    wineChartData: Array,
    currency: String,
    type: String,
    backgroundColors: Array,
    axis: String,
    scalesObject: Object,
    localeString: String
  },
  data() {
    return {
    }
  },
  created() {
    var heightString = 0
    
    if (this.$props.type === 'time') {
      heightString = 400
    } 
    if (this.$props.type === 'top' || this.$props.type === 'top100') {
      heightString = this.wineChartData.length * 34 + 80
    }
    this.canvasHeight = heightString.toString() + "px"
  },
  setup(props) {
    const data = ref({
      venueWines: []
    })
    const labels = ref([])
    const loading = ref(true)
    
    function refreshChart() {
      if (props.type === 'time') {
        labels.value = props.wineChartData.map(field => { return  DateTimeService.dateToFormattedstring(new Date(field.datetime), false, props.localeString)})
      } else {
        if (props.type === 'top' || props.type === 'top100') {
          if (props.type === 'top') {

            labels.value = props.wineChartData.map(field => { 
                return [
                  field.wine.winery.title, field.title
                ]
            })
          }
          if (props.type === 'top100') {
            labels.value = props.wineChartData.map(field => { 
                return [
                field.wine.winery.title, field.title + ' ' + field.year + ' ' + field.bottleSize.amount + ' Liter '
                ]
            })
          }
        } else {
          labels.value = props.wineChartData.map(field => { return  field.title + ' ' + field.year })
        }   
      }
      
      data.value.venueWines = props.wineChartData.map(field => { return field.venueWines });
      data.value.firstParam = props.wineChartData.map(field => { return field.firstParam });
      data.value.secondParam = props.wineChartData.map(field => { return field.secondParam });
    }
    refreshChart()

    const chartData = computed(() => ({
      labels: labels.value,
      datasets: [
        {
          data: data.value.firstParam,
          firstParam: data.value.firstParam,
          secondParam: data.value.secondParam,
          backgroundColor: props.backgroundColors
        },
      ],
    }));
    
    const options = computed(() => ({
      maintainAspectRatio: false,
      borderWidth: 0,
      indexAxis: props.axis, //change direction
      plugins: {
        tooltip: {
          enabled: true,
          displayColors: false,
          callbacks: {
              label: function(context) {
                  let label = context.raw + ' ' + props.currency;
                  return label;
              },
              title: function() {
                  return "";
              }
          }
        },
        legend: false,
        title: {
          display: false,
        },
      },
      scales: props.scalesObject
    }));

    const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
      chartData,
      options,
    });

    return { loading, doughnutChartProps, doughnutChartRef, refreshChart };
  },//EO Setup
  methods: {
  },
  watch: {
    wineChartData() {
      this.refreshChart()
    }
  }
});
</script>