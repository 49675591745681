<template>
  <div class="universalChart">
    <DoughnutChart v-bind="doughnutChartProps" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DoughnutChart, useDoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { ref, computed, defineComponent } from "@vue/composition-api";
import { getVenueCurrency } from '@/utils/functions'
import { useI18n } from '@/composables/useI18n'



Chart.register(...registerables);
export default defineComponent({
  name: "Chart",
  components: {
    DoughnutChart
  },
  props: {
    wineChartData: Object,
    currency: String,
    type: String,
    backgroundColors: Array
  },
  data() {
    return {}
  },
  created () {
  },
  setup(props) {
    const { t, tc } = useI18n()
    const data = ref({
      firstParam: 0,
      secondParam: 0,
    })
    const labels = ref([])
    const loading = ref(true)

    function roundToCurrencyFormat(num) {
      return Math.round(num)
    }

    
    function refreshChart() {
      labels.value = props.wineChartData.data.map(field => { 
        return {
        localeKey: field.localeKey,
        title: field.title
        }
      })
      data.value.venueWines = props.wineChartData.data.map(field => { return field.venueWines });
      data.value.firstParam = props.wineChartData.data.map(field => { return field.firstParam });
      data.value.secondParam = props.wineChartData.data.map(field => { return field.secondParam });
    }
    refreshChart()

    const chartData = computed(() => ({
      labels: labels.value,
      datasets: [
        {
          data: data.value.firstParam,
          firstParam: data.value.firstParam,
          secondParam: data.value.secondParam,
          backgroundColor: props.backgroundColors
        },
      ],
    }));

    const options = computed(() => ({
      backgroundColor: 'red',
      borderWidth: 0,
      plugins: {
        tooltip: {
          enabled: true,
          bodyFont: {
            size: 16
          },
          boxPadding: 4,
          boxWidth: 16,
          boxHeight: 16,
          displayColors: false,
          callbacks: {
            label: function(context) {
                var label = context.label.title || ''
                if (props.type == 'land') {
                  label = t('countries.' + context.label.localeKey)
                } else if (props.type == 'wineType') {
                  label = t('wineTypes.' + context.label.localeKey)
                } else if (props.type == 'region') {
                  label = context.label.localeKey ? t('regions.' + context.label.localeKey) : context.label.title
                } else if (props.type == 'grapeVariety') {
                  label = context.label.localeKey ? t('general.otherGrapeVariety') : context.label.title
                }
                label += ": " + context.parsed + " " + props.currency + " – " + chartData.value.datasets[0].secondParam[context.dataIndex] + " " + tc('general.bottle', 2) 
                return label;
            }
          }
        },
        legend: {
          position: "bottom",
          align: "center",
          labels: {
            font: {
              size: 14
            },
            boxWidth: 16,
            boxHeight: 16,
            color: '#fff',
            padding: 12,
            generateLabels(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                const {labels: {pointStyle}} = chart.legend.options;

                return data.labels.map((label, i) => {
                  const meta = chart.getDatasetMeta(0);
                  const style = meta.controller.getStyle(i);
                  const firstParam = roundToCurrencyFormat(data.datasets[0].firstParam[i])

                  var labelText = label.title + " - " + firstParam + " " + props.currency
                  if (props.type == 'land') {
                    labelText = t('countries.'+ label.localeKey) + " - " + firstParam + " " + props.currency
                  } else if (props.type == 'wineType') {
                    labelText = t('wineTypes.'+ label.localeKey) + " - " + firstParam + " " + props.currency
                  } else if (props.type == 'region') {
                    labelText = (label.localeKey? t('regions.'+ label.localeKey) : label.title) + " - " + firstParam + " " + props.currency
                  } else if (props.type == 'grapeVariety') {
                    labelText = (label.localeKey? t('general.otherGrapeVariety') : label.title) + " - " + firstParam + " " + props.currency
                  }
                  
                  return {
                    text: labelText,
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle: pointStyle,
                    hidden: !chart.getDataVisibility(i),
                    // Extra data used for toggling the correct item
                    index: i
                  };
                });
              }
              return [];
            }
          }
        },
        title: {
          display: false,
        },
      },
    }));

    const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
      chartData,
      options,
    });


    return { loading, doughnutChartProps, doughnutChartRef, refreshChart, t, tc };
  },//EO Setup
  methods: {
  },
  computed: {
    getCurrency() {
      return getVenueCurrency(this.getVenue)
    },
    ...mapGetters(['getVenue'])
  },
  watch: {
    wineChartData() {
      this.refreshChart()
    }
  }
});
</script>