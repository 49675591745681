<template>
  <div style="height: 100%; position:relative;">
    <b-row class="filterRow">
      <b-col>
        <h2>
          {{ $t('dashboard.revenueDetailWidget.title')}}
        </h2>
      </b-col>
      <b-col class="getAllContent">
        <b-button-group class="toggleButtons wineViewFilter">
            <b-button @click="refreshChart('land')" :class="revenueChartFilter=='land'? 'active':''">{{ $t('general.country') }}</b-button>
            <b-button @click="refreshChart('region')" :class="revenueChartFilter=='region'? 'active':''">{{ $t('general.region') }}</b-button>
            <b-button @click="refreshChart('grapeVariety')" :class="revenueChartFilter=='grapeVariety'? 'active':''">{{ $t('general.grapeVariety') }}</b-button>
            <b-button @click="refreshChart('wineType')" :class="revenueChartFilter=='wineType'? 'active':''">{{ $t('general.winetype') }}</b-button>
        </b-button-group>  
      </b-col>
    </b-row>
    <div class="spacer l"></div>
    <b-row >  
      <b-col>
        <div ref="revenueContainer" class="chartOuterContainer">
          <UniversalChart v-if="chartTypeData.revenueTotal > 0" class="chartContainer" :backgroundColors="chartBackgrounds" :wineChartData="chartTypeData" :currency="getCurrency" :type="revenueChartFilter" />
        </div>
      </b-col>
    </b-row>
    <div class="emptyResutsWidget" v-if="chartTypeData.revenueTotal <= 0">
      <span class="emptyState">{{ $t('dashboard.revenueDetailWidget.noSales')}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UniversalChart from '@/components/charts/UniversalChart.vue'
import { getVenueCurrency } from '@/utils/functions'

export default {
  name: "RevenueDetailWidget",
  components: {
   UniversalChart,
  },
  data() {
    return {
      chartTypeData: {},
      revenueChartFilter: 'land',
      chartBackgrounds: [
        "#7edd37",
        "#d631be",
        "#ffc107",
        "#27d6ca",
        "#dc3545",
        "#ffa3a3",
        "#7faaff",
        "#a6ffbb",
        "#d375ff",
        "#ffffff",
      ]
    }
  },
  props: {
    from: String,
    to: String,
    revenueDetailData: Object,
  },
  created () { 
    this.refreshChart(this.revenueChartFilter)
  },
  methods: {
    transformDataToChart (dataObject) {
      var returnObject = dataObject
      returnObject.firstParam = dataObject.revenueAll.toFixed(2)
      returnObject.secondParam = dataObject.soldAmount
      return returnObject
    },
     transformOuterDataToChart (dataObject) {
      var returnObject = dataObject
      returnObject.firstParam = dataObject.revenueTotal.toFixed(2)
      returnObject.secondParam = dataObject.soldAmountTotal
      returnObject.data = dataObject.data.map(data => this.transformDataToChart(data))
      return returnObject
    },
    refreshChart (filter) {
      this.revenueChartFilter = filter
      switch (filter) {
        case "land":
          this.chartTypeData = this.transformOuterDataToChart(this.revenueDetailData.landChartData)
          break;
        case "region":
          this.chartTypeData = this.transformOuterDataToChart(this.revenueDetailData.regionChartData)
          break;
        case "grapeVariety":
          this.chartTypeData = this.transformOuterDataToChart(this.revenueDetailData.grapeVarietyChartData)
          break;
        case "wineType":
          this.chartTypeData = this.transformOuterDataToChart(this.revenueDetailData.wineTypeChartData)
          break;
        default:
          this.chartTypeData = this.transformOuterDataToChart(this.revenueDetailData.landChartData)
      }
    }
  },
  computed: {
    getCurrency() {
      return getVenueCurrency(this.getVenue)
    },
    ...mapGetters([ 'getVenue'])
  },
  watch: {
    from: function () { 

    },
    revenueDetailData: function () {
      this.refreshChart(this.revenueChartFilter)
    },
    to: function () {
     
      
    }
  }
};
</script>

<style>
.universalChart.chartContainer {
  top: 0 !important;
}
</style>