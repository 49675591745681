<template>
  <div style="height: 100%; position:relative;">
  <b-row  class="filterRow">
    <b-col>
      <h2>
        {{ $t('dashboard.inOutWidget.title') }}
      </h2>
    </b-col>
    <b-col class="getAllContent">
      <b-button-group class="toggleButtons wineViewFilter">
          <b-button @click="filterActivities('goodsIn')" :class="filterState=='goodsIn'? 'active':''">{{ $t('dashboard.inOutWidget.goodsIn')}}</b-button>
          <b-button @click="filterActivities('goodsOut')" :class="filterState=='goodsOut'? 'active':''">{{ $t('dashboard.inOutWidget.goodsOut')}}</b-button>
      </b-button-group>  
    </b-col>
  </b-row>
  <div class="spacer m"></div>
  <div class="inOutContainer inOutList" v-if="filteredActivities.length > 0" >
    <b-row class="inOutHeading">
      <b-col cols="8">
        {{$t('general.wine')}}
      </b-col>
      <b-col cols="2">
        {{$t('general.date')}}
      </b-col>
      <b-col cols="2">
        {{$t('general.amount')}}
      </b-col>
    </b-row>
    <b-row class="activityRow"  v-for="(activity) in filteredActivities" :key="activity.index">
      <b-col cols="8">
        <p class="lightText" :key="activity.index">{{ activity.winery }}</p>
        <p class="whiteText" :key="activity.index">{{ activity.wineTitle }} <span v-if="activity.wineYear > 0" class="lightText">{{ activity.wineYear }}</span></p>
      </b-col >
      <b-col cols="2">
        <p :key="activity.index">{{ formatDate(activity.date)   }}</p>
      </b-col>
      <b-col cols="2">
        <p :key="activity.index">{{Math.abs(activity.diff)}} {{ activity.fieldName === 'amountGlasses' ? $t('dashboard.inOutWidget.glasses') : $t('dashboard.inOutWidget.bottles') }}</p>
      </b-col>
      </b-row>
  </div>
  <div class="emptyResutsWidget" v-if="filteredActivities.length <= 0">
    <span class="emptyState" v-if="this.filterState == 'goodsIn'">{{ $t('dashboard.inOutWidget.noGoodsIn')}}</span>
    <span class="emptyState" v-if="this.filterState == 'goodsOut'">{{ $t('dashboard.inOutWidget.noGoodsOut')}}</span>
  </div>
  </div>
</template>

<script>
import DataService from '@/services/StrapiService'
import DateTimeService from '@/services/DateTimeService'

export default {
  name: "InOutWidget",
  components: {
  },
  data() {
    return {
      activities: {},
      filterState: 'goodsIn',
  
    }
  },
  props: {
    from: String,
    to: String,
  },
  created () {
    this.loadActivities()
  },
  methods: {
    loadActivities () {
        this.loading = true
        DataService.getGoodsMovement(this.from, this.to).then((response) => {
          this.activities = response['data'].data
          this.loading = false
        }).catch((err) => {
          this.loading = false
          console.log(err)
        })
      },
      filterActivities (filterState) {
        this.filterState = filterState
      },
      formatDate (dateString) {
        var date = new Date(dateString)
        return DateTimeService.dateToFormattedstring(date, false, this.$i18n.locale)
      }
  },
  computed: {
    filteredActivities() {
      if (this.filterState == 'goodsIn' && this.activities.added) {
        return this.activities.added
        } else if (this.filterState == 'goodsOut') {
          return this.activities.sold
        } else return []
    }

  },
  watch: {
    from: function () { 
      this.loadActivities()
      
    },
    to: function () {
           
    }
  }
};
</script>
<style>
p {
  font-size: 12px;
}
.inOutHeading {
  font-size: 12px;
  font-weight: bold;
  color: #cbc6d7;
  margin-bottom: 15px;
}
.activityRow {
  margin-bottom: 16px;
}

.lightText {
  font-size: 12px;
  font-weight: 300 !important;
  font-style: normal !important;
  color: #e6e1f3;
  line-height: 0.83;
}
.wineTitle {
  font-size: 14px;
  font-weight: bold;
}
.wineTitle p {
  display: inline;
}


</style>